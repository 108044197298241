<template>
  <v-container fill-height fluid>
    <v-layout wrap>
      <v-flex md12 sm12 lg12 v-if="notAllowed">
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-alert type="warning">Diese Wartung wurde bereits abgeschlossen und kann nicht mehr
              verändert werden.</v-alert>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-btn color="primary" top right @click="$router.go(-1)">Zurück</v-btn>
          </v-col>
        </v-row>
      </v-flex>
      <v-flex v-else md12 sm12 lg12>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="6">
            <div class="card">
              <div class="card-header card-header-primary">
                <div class="card-title">
                  Wartungsinfomation 
                  <v-chip
                      v-if="wartung.status == '0'"
                      color="#9c27b0"
                      dark
                      class="ml-5"
                      >Erstellt</v-chip
                    >
                    <v-chip
                      v-if="wartung.status == '1'"
                      color="orange"
                      dark
                      class="ml-5"
                      >In Bearbeitung</v-chip
                    >
                    <v-chip v-if="wartung.status == '2'" color="error" class="ml-5"
                      >Übermittelt</v-chip
                    >
                    <v-chip
                      v-if="wartung.status == '3'"
                      color="success"
                      class="ml-5"
                      >Abgeschlossen</v-chip
                    >
                </div>
              </div>
              <div class="card-body">
               
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td>
                          <b>Wartungsblockname</b>
                        </td>
                        <td>{{ wartung.name }}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Wartung durchgeführt</b>
                        </td>
                        <td>{{ wartung.username }}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Nächste Wartung</b>
                        </td>
                        <td>{{ $formatDate(wartung.nextservice) }}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Letzte Wartung</b>
                        </td>
                        <td>{{ $formatDate(wartung.lastservice) }}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Referenz-Datum für Wartungszyklus</b>
                        </td>
                        <td>{{ $formatDate(wartung.refdate) }}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Wartungszyklus (Im Jahr)</b>
                        </td>
                        <td>{{ wartung.cycle }}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Zeitstempel</b>
                        </td>
                        <td>
                          <small>Erstellt am:
                            {{ $formatDate(wartung.created_at) }}</small>
                          <br />
                          <small>Aktualsiert am:
                            {{ $formatDate(wartung.updated_at) }}</small>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Auftrag</b>
                        </td>
                        <td>
                          <v-btn v-if="wartung.assignmentid" color="primary" outlined @click="
        $router.push(
          '/assignment/' + wartung.assignmentid
        )
        ">Auftrag anzeigen</v-btn>
                          <span v-else><i>Kein Auftrag zugewiesen</i></span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Dokumente</b>
                        </td>
                        <td>
                          <ul v-if="documents.length">
                            <li v-for="(doc, i) in documents" :key="i">
                              <span class="link" @click="$openDoc(doc)">{{
        doc.filename
      }}</span>
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr v-if="wartung.status == '2'">
                        <td>
                          <b>Status</b>
                        </td>
                        <td class="pt-2" style="height:70px; display: flex; align-items:baseline">
                          <v-btn class="ml-3" v-if="role != 'worker'" color="primary" @click="changeStatus(1)">Zurück zuweisen</v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-card outlined>
              <v-card-title>Block-Anlagen</v-card-title>
              <v-card-text>
                <v-data-table :headers="headers" :items="anlagen" :items-per-page="-1" show-select class="row-pointer"
                  @click:row="handleClick" item-key="id" v-model="selected" hide-default-footer :search="search">
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-spacer></v-spacer>
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" fab @click="
        $router.push(
          '/anlage/new/' +
          wartung.customerid +
          '/' +
          wartung.blockid
        )
        " color="success">
                            <v-icon>add</v-icon>
                          </v-btn>
                        </template>
                        <span>Neue Anlage für diesen Wartungs-Block erstellen</span>
                      </v-tooltip>
                    </v-toolbar>
                    <v-col cols="12" offset-md="6" offset-lg="6" sm="12" md="6" lg="6">
                      <v-text-field v-model="search" append-icon="search" label="Suche"></v-text-field>
                    </v-col>
                  </template>
                  <template v-slot:item.commentintern="{ item }">
                    <div>{{ getComment(item.commentintern) }}</div>
                  </template>
                  <template v-slot:item.status="{ item }">
                    <div class="text-center">
                      <i style="color: green" v-if="item.status === '1' || item.status === 1"
                        class="material-icons">check_circle</i>
                      <i v-else style="color: red" class="material-icons">remove_circle</i>
                    </div>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <div class="text-center inline-flex">
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" @click="
        goto(
          '/anlagewartung',
          [mode, item.id, wid, blockid],
          item.offline
        )
        " color="primary" small fab>
                            <i class="material-icons">playlist_add_check</i>
                          </v-btn>
                        </template>
                        <span>Wartung für Anlage dürchführen</span>
                      </v-tooltip>
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" @click.stop="duplicateItem(item)" color="primary" small fab>
                            <i class="material-icons">filter_1</i>
                          </v-btn>
                        </template>
                        <span>Anlagen duplizieren</span>
                      </v-tooltip>
                      <v-tooltip left v-if="role === 'admin' || role === 'pl'">
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" @click.stop="printAnlageSum(item.id, item.name)" color="primary" small fab>
                            <i class="material-icons">print</i>
                          </v-btn>
                        </template>
                        <span>Wartungsbericht für diese Anlage erstellen</span>
                      </v-tooltip>
                    </div>
                  </template>
                </v-data-table>
                <v-alert v-if="hasOffline" type="warning">Für diese Wartung befinden sich Daten im
                  Offline-Speicher.</v-alert>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-card class="mx-auto" flat>
              <v-card-text>
                <v-data-table :headers="headersdocuments" height="inherit" hide-default-footer :items-per-page="-1"
                  :items="wartungsdocuments">
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Dokumente ({{
        wartungsdocuments.length
      }})</v-toolbar-title>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.created_at="{ item }">{{
        $formatDateTime(item.created_at)
      }}</template>
                  <template v-slot:item.filename="{ item }">
                    <div class="link" @click="$openDoc(item)">
                      {{ item.filename }}
                    </div>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <div class="text-center inline-flex">
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn v-if="role != 'worker' || item.created_by == userid" v-on="on"
                            @click="confirmDeleteDoc(item)" color="error" small fab>
                            <i class="material-icons">delete</i>
                          </v-btn>
                        </template>
                        <span>Dokument löschen</span>
                      </v-tooltip>
                    </div>
                  </template>
                  <template v-slot:footer>
                    <hr class="my-5" />
                    <v-row>
                      <v-col cols="12" sm="9" md="9" lg="9">
                        <v-file-input v-model="docs" color="primary accent-4" counter
                          label="Hier klicken um Dokumente hochzuladen" multiple
                          placeholder="Hier klicken um Dokumente hochzuladen" prepend-icon="mdi-paperclip" outlined
                          :show-size="1000"></v-file-input>
                      </v-col>
                      <v-col cols="12" sm="3" md="3" lg="3">
                        <v-btn @click="uploadDocs()" color="primary" :disabled="docs.length === 0 || !online">Dokumente
                          Hochladen</v-btn>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
            <!-- <div class="card-header card-header-primary">
                <div class="card-title">Dokumente</div>
              </div>
              <div class="card-body" v-if="wartung.images">
                <div
                  class="img-thumb"
                  v-for="image in wartung.images.split(';')"
                  :key="image"
                >
                  <v-img
                    :src="getImage(image)"
                    contain
                    @click="showImg(image)"
                  ></v-img>
                </div>
              </div>
            </div> -->
          </v-col>
          <!--  <v-col v-if="mode === 'execute'" cols="12" xs="12" sm="10" md="7" lg="5">
            <div class="card-title">Unterschrift Service-Techniker</div>
            <vueSignature h="240px" ref="signature" :sigOption="option"></vueSignature>
            <v-btn color="error" text @click="clear()">Löschen</v-btn>
            <v-alert
              v-if="missingSig"
              type="error"
            >Sie müssen die Wartung mit ihrer Unterschrift bestätigen.</v-alert>
          </v-col>
          <v-col v-else cols="12" xs="12" sm="10" md="7" lg="5">
            <div class="card-title">Unterschrift Service-Techniker</div>
            <v-img :src="wartung.signature" contain></v-img>
          </v-col>-->
          <v-col cols="12" lg="12">
            <v-btn color="success" v-if="mode == 'execute'" @click="submitWartung()" :disabled="!online">Wartung
              übermitteln</v-btn>
            <v-btn color="success" v-else @click="submitWartung()" :disabled="!online">Wartung abschließen</v-btn>
            <!--    <v-btn
              v-if="role === 'admin' || role === 'pl'"
              style="position: absolute; right: 23px"
              @click="printWartungSum()"
              color="primary"
            >
              <i class="material-icons">print</i>
              Wartungsbericht erstellen
            </v-btn> -->
            <v-btn v-if="role === 'admin' || role === 'pl'" :disabled="!selected.length"
              style="position: absolute; right: 23px" @click="printWartungen()" :loading="loading" color="primary">
              <v-icon>print</v-icon>
              Wartungsberichte erstellen
            </v-btn>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialog" persistent max-width="590">
      <v-card>
        <v-card-title class="headline">Nächstes Wartungsdatum setzen</v-card-title>
        <v-card-text>
          <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
            offset-y min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field v-model="formattedDate" label="Nächste Wartung am" prepend-icon="event" readonly
                v-on="on"></v-text-field>
            </template>
            <v-date-picker no-title first-day-of-week="1" v-model="nextdate" @input="menu = false"></v-date-picker>
          </v-menu>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="endWartung()">Abschliessen</v-btn>
          <v-btn color="error" text @click="dialog = false">Abbrechen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirm" persistent max-width="590">
      <v-card>
        <v-card-title class="headline">Wartung Abschliessen</v-card-title>
        <v-card-text>
          <p>Folgende Anlagen sind noch nicht oder nicht komplett geprüft.</p>
          <ul style="color: red; margin-bottom: 10px">
            <li v-for="task in requiredTasks" :key="task.id">{{ task }}</li>
          </ul>
          <p>
            Sind Sie sicher, dass Sie die Wartung abschliessen wollen? Sie
            können nach der Übermittlung die Wartung nicht mehr verändern.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="
        dialog = false;
      finalizeWartung();
      ">Abschliessen</v-btn>
          <v-btn color="error" text @click="confirm = false">Abbrechen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="image" width="90%" transition="dialog-bottom-transition">
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="
        image = false;
      selectedimage = '';
      ">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-img :src="getImage(selectedimage)" contain></v-img>
    </v-dialog>
    <v-dialog v-model="duplicateDialog" max-width="400px">
      <v-card>
        <v-card-title class="headline">Anlage Duplizieren</v-card-title>
        <v-card-text v-if="duplicateAnlage">
          <p>
            Sind Sie sicher, dass sie die Anlage
            <span class="bold"> {{ duplicateAnlage.name }} </span> dublizieren
            möchten?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="_duplicateItem()">Dublizieren</v-btn>
          <v-btn color="error" text @click="duplicateDialog = false">Abbrechen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      option: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255,255,255)",
      },
      missingSig: false,
      notAllowed: false,
      hasOffline: false,
      anlagen: [],
      selected: [],
      dialog: false,
      loading: false,
      documents: [],
      docs: [],
      wartungsdocuments: [],
      duplicateDialog: false,
      duplicateAnlage: null,
      confirm: false,
      requiredTasks: [],
      wartung: {},
      selectedimage: "",
      image: false,
      menu: false,
      search: "",
      nextdate: "",
      stati: [
        {
          id: "0",
          text: "Erstellt",
        },
        {
          id: "1",
          text: "In Bearbeitung",
        },
        {
          id: "2",
          text: "Übermittelt",
        },
        {
          id: "3",
          text: "Abgeschlossen",
        },
      ],
      headersdocuments: [
        {
          text: "ID",
          align: "left",
          sortable: false,
          value: "id",
        },
        {
          text: "Bezeichnung",
          align: "left",
          sortable: false,
          value: "filename",
        },
        {
          text: "Hochgeladen von",
          align: "left",
          value: "creator",
        },
        {
          text: "Hochgeladen am",
          align: "left",
          value: "created_at",
        },
        {
          text: "Aktion",
          align: "center",
          sortable: false,
          value: "action",
        },
      ],
      headers: [
        {
          text: "ID",
          align: "left",
          sortable: false,
          value: "id",
        },
        {
          text: "Anlagenbezeichnung",
          align: "left",
          sortable: false,
          value: "name",
        },
        {
          text: "Gebäude",
          align: "left",
          sortable: false,
          value: "gebaeude",
        },
        {
          text: "Standort",
          align: "left",
          sortable: false,
          value: "standort",
        },
        {
          text: "Typ",
          align: "left",
          sortable: false,
          value: "typ",
        },
        {
          text: "Durchgeführt von",
          align: "center",
          sortable: false,
          value: "username",
        },
        {
          text: "Kommentar intern",
          align: "left",
          sortable: false,
          value: "commentintern",
        },
        {
          text: "Status",
          align: "center",
          sortable: false,
          value: "status",
        },
        {
          text: "Aktion",
          align: "center",
          sortable: false,
          value: "action",
        },
      ],
    };
  },
  mounted: function () {
    this.getWartung();
  },
  computed: {
    wid() {
      return this.$route.params.wid;
    },
    blockid() {
      return this.$route.params.blockid;
    },
    role() {
      return this.$store.getters.user.role;
    },
    userid() {
      return this.$store.getters.user.id;
    },
    mode() {
      return this.$route.params.mode;
    },
    online() {
      return this.$store.getters.online;
    },
    assignmentid() {
      return this.$route.params.assignmentid;
    },
    formattedDate() {
      return this.$formatDate(this.nextdate);
    },
  },
  methods: {
    clear() {
      this.$refs.signature.clear();
    },
    showImg(img) {
      this.selectedimage = img;
      this.image = true;
    },
    getComment(comment) {
      if (!comment) {
        return "";
      }
      return JSON.parse(comment);
    },
    handleClick(item) {
      this.goto(
        "/anlagewartung",
        [this.mode, item.id, this.wid, this.blockid],
        item.offline
      );
    },
    duplicateItem(item) {
      this.duplicateAnlage = item;
      this.duplicateDialog = true;
    },
    uploadDocs() {
      this.$uploadDocs(this.docs, this.wid, "wartung", "company")
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (response.data && response.data.success && response.data.files) {
            const wid = this.wid;
            const username = this.$store.getters.user.name;
            const userid = this.userid;
            response.data.files.forEach((file) => {
              this.wartungsdocuments.push({
                id: file.id,
                path: file.path,
                filename: file.filename,
                created_at: this.$formatDateTimeISO(new Date()),
                created_by: userid,
                creator: username,
                refid: wid,
              });
            });
            this.docs = [];
            const msg = "Dokumente wurden erfolgreich hochgeladen.";
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
          } else {
            const msg =
              "Dokumente konnten nicht hochgeladen werden. Bitte versuchen Sie es erneut.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    _duplicateItem() {
      const item = this.duplicateAnlage;
      let formData = new FormData();
      formData.append("name", item.name);
      formData.append("standort", item.standort);
      formData.append("typ", item.typ);
      formData.append("gebaeude", item.gebaeude);
      formData.append("baujahr", item.baujahr);
      formData.append("seriennummer", item.seriennummer);
      formData.append("listitems", item.items);
      formData.append("mode", "new");
      formData.append("customerid", item.customerid);
      formData.append("comment", "");
      formData.append("blockid", this.blockid);
      this.$http({
        method: "post",
        url: "editAnlage.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.data.success ||
            response.data.indexOf('"success":true') > -1
          ) {
            const msg = "Die Anlage wurde erfolgreich kopiert.";
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
            const newItem = JSON.parse(JSON.stringify(item));
            newItem.id = response.data.id;
            this.anlagen.push(newItem);
            this.duplicateAnlage = null;
            this.duplicateDialog = false;
          } else {
            const msg =
              "Beim Speichern ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch(error => {
          const msg =
            "Beim Speichern ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.";
          const color = "error";
          this.$store.dispatch("snackbar", { msg, color });
        });
    },
    changeStatus(status) {
      const id = this.wid;
      let formData = new FormData();
      if (!id) {
        alert("Es ist ein fehler aufgetretten");
        return;
      }
      formData.append("id", id);
      formData.append("status", status);
      this.$http({
        method: "post",
        url: "changeWartungStatus.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (response.data && response.data.success) {
            this.wartung.status = status + "";
            const msg = "Der Status wurde erfolgreich geändert";
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
          } else {
            const msg =
              "Der Status konnte nicht geändert werden. Bitte versuchen Sie es erneut.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch((error) => {
          const response = error.response.data;
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
            return;
          }
          const msg =
            "Der Status konnte nicht geändert werden. Bitte versuchen Sie es erneut.";
          const color = "error";
          this.$store.dispatch("snackbar", { msg, color });
        });
    },
    getWartung() {
      var self = this;
      if (this.online) {
        let formData = new FormData();
        formData.append("wid", this.wid);
        formData.append("blockid", this.blockid);
        this.$http({
          method: "post",
          url: "getWartungDetails.php",
          data: formData,
        })
          .then((response) => {
            if (response.data && response.data.success) {
              if (response.data.data) {
                const data = response.data.data;
                if (
                  this.mode === "execute" &&
                  this.role === "worker" &&
                  data.status == "2"
                ) {
                  this.notAllowed = true;
                  return;
                }
                self.wartung = response.data.data;
              }

              if (response.data.documents) {
                this.documents = response.data.documents;
              }
              if (response.data.wartungsdocuments) {
                this.wartungsdocuments = response.data.wartungsdocuments;
              }
              const anlagen = response.data.anlagen;
              if (anlagen) {
                self.anlagen = anlagen;
                const data = this.$store.getters.offlinedata;
                if (
                  data &&
                  data.offlinewartungen &&
                  data.offlinewartungen.length
                ) {
                  const wid = this.wid;
                  anlagen.forEach((anlage) => {
                    const id = anlage.id;
                    data.offlinewartungen.forEach((off) => {
                      if (off.wid === wid && off.anlageid === id) {
                        this.hasOffline = true;
                      }
                    });
                  });
                }
              }
            }
          })
          .catch(function (error) {
            error;
          });
      } else {
        const data = this.$store.getters.offlinedata;
        const wid = this.wid;
        const blockid = this.blockid;
        if (data && data.wartungsdetails) {
          const detail = data.wartungsdetails.find((c) => {
            return c.wid === wid;
          });
          if (detail) {
            self.wartung = detail;
          }
        }
        if (data && data.blocke) {
          const block = data.blocke.find((c) => {
            return c.id === blockid;
          });
          if (block) {
            const anlagenwartungen = data.anlagenwartungen;
            const anlagenids = data.blocke[0].anlagen.split(",");
            const anlagen = data.anlagen;
            const offlinewartungen = data.offlinewartungen;
            let res = [];
            anlagenids.forEach((id) => {
              let offWartung, aWartung;
              if (offlinewartungen && offlinewartungen.length) {
                offWartung = offlinewartungen.find((w) => {
                  return w.wid === wid && w.anlageid === id;
                });
                if (offWartung) {
                  offWartung.offline = true;
                  res.push(offWartung);
                }
              }
              if (anlagenwartungen && anlagenwartungen.length) {
                aWartung = anlagenwartungen.find((w) => {
                  return w.wartungsid === wid && w.anlageid === id;
                });
                if (!offWartung && aWartung) {
                  res.push(aWartung);
                }
              }
              if (anlagen && anlagen.length) {
                if (!offWartung && !aWartung) {
                  const anlage = anlagen.find((a) => {
                    return a.id === id;
                  });
                  if (anlage) {
                    res.push(anlage);
                  }
                }
              }
            });
            self.anlagen = res;
          }
        }
      }
    },
    printWartungen() {
      let formData = new FormData();
      const ids = this.selected.map((s) => {
        return s.id;
      });

      formData.append("ids", ids.join(","));
      formData.append("wid", this.wid);
      var self = this;
      this.loading = true;
      this.$http({
        method: "post",
        url: "printWartungen.php",
        data: formData,
        responseType: "blob",
      })
        .then((response) => {
          this.loading = false;
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (response.status === 200 && response.data) {
            const blobUrl = URL.createObjectURL(
              new Blob([response.data], { type: "application/zip" })
            );
            const link = document.createElement("a");
            link.style.display = "none";
            link.href = blobUrl;
            link.download = "VDMA_.zip";
            //link.type = "application/zip";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(blobUrl);
            const msg = "Der Druck wurde erfolgreich erstellt.";
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
            /*  const url =
              this.$http.defaults.serverURL +
              "/print/prints/" +
              response.data.file;
            window.open(url, "_blank"); */
          } else {
            const msg =
              "Der Druck konnte nicht erstellt werden. Bitte versuchen Sie es erneut";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch(error => {
          this.loading = false;
          const msg =
            "Der Druck konnte nicht erstellt werden. Bitte versuchen Sie es erneut";
          const color = "error";
          self.$store.dispatch("snackbar", { msg, color });
        });
    },
    printWartungSum() {
      let formData = new FormData();
      formData.append("wid", this.wid);
      var self = this;
      this.$http({
        method: "post",
        url: "printWartungSummary.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (response.data && response.data.success && response.data.file) {
            const url =
              this.$http.defaults.serverURL +
              "/print/prints/" +
              response.data.file;
            window.open(url, "_blank");
          } else {
            const msg =
              "Der Druck konnte nicht erstellt werden. Bitte versuchen Sie es erneut";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch(function (error) {
          const msg =
            "Der Druck konnte nicht erstellt werden. Bitte versuchen Sie es erneut";
          const color = "error";
          self.$store.dispatch("snackbar", { msg, color });
        });
    },
    getImage(img) {
      return this.$http.defaults.serverURL + "/uploads/" + img;
    },
    printAnlageSum(id, name) {
      let formData = new FormData();
      formData.append("wid", this.wid);
      formData.append("anlageid", id);

      var self = this;
      this.$http({
        method: "post",
        url: "printWartungAnlage.php",
        data: formData,
        responseType: "blob",
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (response.status === 200 && response.data) {
            const blobUrl = URL.createObjectURL(
              new Blob([response.data], { type: "application/pdf" })
            );
            const link = document.createElement("a");
            link.style.display = "none";
            link.href = blobUrl;
            link.download = "VDMA_" + name + ".pdf";
            link.type = "application/pdf";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(blobUrl);
            const msg = "Der Druck wurde erfolgreich erstellt.";
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
          } else {
            const msg =
              "Der Druck konnte nicht erstellt werden. Bitte versuchen Sie es erneut";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch(function (error) {
          const msg =
            "Der Druck konnte nicht erstellt werden. Bitte versuchen Sie es erneut";
          const color = "error";
          self.$store.dispatch("snackbar", { msg, color });
        });
    },
    setNextDate() {
      this.dialog = true;
      const wartung = this.wartung;
      if (wartung.refdate && wartung.cycle) {
        const month = 12 / parseInt(wartung.cycle);
        const refDate = new Date(wartung.refdate);
        const now = new Date();
        refDate.setFullYear(now.getFullYear());
        while (refDate < now) {
          refDate.setMonth(refDate.getMonth() + month);
        }
        this.nextdate = this.$formatDateISO(refDate);
      } else if (
        wartung.nextservice != null &&
        wartung.nextservice !== "0000-00-00"
      ) {
        this.nextdate = wartung.nextservice;
      }
    },
    submitWartung() {
      if (this.mode === "execute") {
        this.checkWartung();
      } else {
        if (this.role === "admin" || this.role === "pl") {
          this.setNextDate();
        }
      }
    },
    endWartung() {
      let formData = new FormData();
      formData.append("id", this.wid);
      formData.append("blockid", this.blockid);
      const date = Math.round(new Date(this.nextdate).getTime() / 1000);
      formData.append("nextservice", date);
      this.$http({
        method: "post",
        url: "endWartung.php",
        data: formData,
        config: {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/json",
          },
        },
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.authorization
          ) {
            alert(
              "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator"
            );
            return;
          }
          if (response.data && response.data.success) {
            const msg = "Die Wartung wurde erfolgreich abgeschlossen";
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
            this.$router.push("/");
          } else {
            const msg =
              "Die Wartung konnte nicht abgelossen werden. Bitte versuchen Sie es erneut.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch(function (error) {
          console.log(error);
          const msg =
            "Die Wartung konnte nicht abgelossen werden. Bitte versuchen Sie es erneut.";
          const color = "error";
          this.$store.dispatch("snackbar", { msg, color });
        });
    },

    checkWartung() {
      /* if (this.$refs.signature.isEmpty()) {
        this.missingSig = true;
        return;
      } */
      this.missingSig = false;
      let showConfirm = false;
      this.requiredTasks = [];
      this.anlagen.forEach((item) => {
        if (!item.status || item.status === "0") {
          showConfirm = true;
          this.requiredTasks.push(item.name);
        }
      });
      if (showConfirm) {
        this.confirm = true;
      } else {
        this.finalizeWartung();
      }
    },

    finalizeWartung() {
      let formData = new FormData();
      formData.append("id", this.wid);
      formData.append("blockid", this.blockid);
      formData.append("cycle", this.wartung.cycle);
      /* const sig = this.$refs.signature.save();
      formData.append("signature", sig); */
      this.$http({
        method: "post",
        url: "finalizeWartung.php",
        data: formData,
        config: {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/json",
          },
        },
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (response.data && response.data.success) {
            const msg =
              "Die Wartung wurde erfolgreich übermittelt und ist nun abgeschlossen";
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
            if (this.assignmentid) {
              this.$router.push("assigment/" + this.assignmentid);
            } else {
              this.$router.push("/");
            }
          } else {
            const msg =
              "Die Wartung konnte nicht gespeichtert werden. Bitte versuchen Sie es erneut.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch(function (error) {
          console.log(error);
          const msg =
            "Die Wartung konnte nicht gespeichtert werden. Bitte versuchen Sie es erneut.";
          const color = "error";
          this.$store.dispatch("snackbar", { msg, color });
        });
    },
    goto(site, params, offline) {
      if (offline) {
        params.push("o");
      }
      const path = site + "/" + params.join("/");
      this.$router.push({ path: path });
    },
  },
};
</script>
